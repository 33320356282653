import React from 'react'
import MainContent from '../Portfolio/MainContent'

const Portfolio = () => {
    return (
        <React.Fragment>
            <MainContent />
        </React.Fragment>
    )
}

export default Portfolio
